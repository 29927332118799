
*::-webkit-scrollbar {
    height: 64px;
    width: 8px;
    
  }
  
  *::-webkit-scrollbar-thumb {
    background-color: #CBCBCB;
    border-radius: 4px;
}
  
  *::-webkit-scrollbar-track {
    background: transparent;
  }
