
h3.styled-title {
  margin:0;
}

div.delivery-method{
  margin-top: 40px;
  display: flex;
  svg{
    &+div.delivery-title{
      margin-left: 24px;
      margin-bottom: 12px;
      p{
        margin-bottom:4px;
      }
    }
    margin-top: 2px;
    width: 20px;
    height: 20px;
  }
}
div.payment-terms{
  padding-top:38px;
  padding-bottom: 30px;
  display:flex;

  svg{
    &+div.terms-content{
      margin-left: 24px;
    }
    width: 20px;
    height: 20px;
  }
}

.radio-label{
  margin-left: 42px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  >div.radio-container{
    height: 16px;
  }
  p{
   cursor: pointer; 
   &:nth-child(2){
     margin-left: 17px;
     margin-bottom: 0px;
   }
  }
}
div.modal-body .buttons-container-delivery {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  div {
    display: flex;
    align-items: center;
    &:not(:first-child) {
      margin-left: 32px;
    }
    input.secondary{
      width: 60px;
      margin-right: 32px;
    }
  }
}