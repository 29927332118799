.modal-body {
  .title {
    margin-top: 0px; 
    margin-bottom: 40px;
  }
  
  .field-container {
    margin-bottom: 24px;
    div.input-container{
      input{
        width: calc(100% - 1px);
      }
    }
    &:nth-child(3){
      margin-bottom: 40px;
    }
    svg {
      flex: 0 0 16px;
      height: 20px;
      margin-right: 17px;
    }
  
    
    p {
      margin: 0;
      flex: 0 0 120px;
    }
  
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .buttons-container {
    display: block;
    div {      
      float: right;      
      &:not(:last-child) {
        margin-left: 32px;
      }
      input{
        &.secondary{
          width: 60px;
          margin-right: 32px;
        }        
      }

    }
  }
}
