.customer-details-nav-container{
  position:fixed;
  width: 350px;
}
.customer-details-nav {
  border-bottom: 1px solid #e3e3e3;
  display: flex;
  align-items: center;
  height: 72px;
  padding: 16px 32px 15px 32px;

  .sidebar-title {
    p, h3 {
      margin: 0;                  
    }
  }

  .icon-container {
    margin-left: auto;

    svg {
      width: 12px;
      cursor: pointer;
    }
  }
}

.customer-details {
  border-collapse: separate;
  border-spacing: 0px 24px;
  padding: 32px; 

  tr {
    td {
      height: 20px;
      
      &.title {
        display: flex;
        align-items: center;
      }
  
      p, span.badge {
        margin: 0
      }
  
      &:first-child {
        width: 140px;

        svg {
          width: 16px;
          height: 16px;
          margin-right: 16px;
        }
      }
    }
  }
}
.actions-history{
  padding: 0 31px;
  margin-right: 2px;
  overflow-y: auto;
  overflow-x: hidden;
  height: 70vh;
  .actions{
    .actions-container {
      height: 88px;
      padding-top: 24px;
      border-bottom: 1px solid rgba(127, 127, 127, 0.5);
        .action-avatar{
          width: 40px;
          float: left;
          margin: 0;
        }
        .action-text{
          float: left;
          margin-left: 16px;
          div{
            display:table
          }
            .user-name{
              color: #888888;
            }
            .action-comment{
              display:inline-block;
              width: auto;
              margin-top: 4px;
              color: #888888;
              font-size: 12px
            }
        }
    }
  }
}
.actions-filter{
  display: flex; 
  padding-top: 30px;
  padding-left: 32px;
  align-items: baseline;
  cursor: pointer;
  >p{
    color: #9db4c4;
    margin-bottom: 6px;
  }
  div.reference{
    div{
      &:hover{
        background-color: transparent;
      }
    }
  }
  .actions-dropdown{
    margin-left: 8px;
    padding: 8px 0 4px 8px;
    color: #252839;
    min-width: 51px;
    height: 32px;
    &:hover{
      background-color: rgba(250, 250, 250, 0.6);
      border-bottom: 2px solid #DEDEDE
    }
    > svg {
      fill: #6887b4;
      width: 10px;
      height: 5px;
      margin-left: 4px;
      margin-bottom: 2px;
    }
  }
  > div{
    ul{
      max-height: 300px;
      max-width: 182px;
      padding: 6px 0;
      margin-top: -32px;      
      >li{
        padding: 0 21px;
        margin: 0;
        color: #252839;
      }
    }
  } 
}