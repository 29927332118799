@keyframes slideInUp {
  from {
    transform: translate3d(0, 400px, 0);
    visibility: visible;
  }
}

.slideInUp {
  animation-name: slideInUp;
}

@keyframes slideOutDown {
  from {
    transform: translate3d(0, 0, 0);  
  }



  to {
    visibility: hidden;
    transform: translate3d(0, 400px, 0);
  }
}

.slideOutDown {
  animation-name: slideOutDown;
}

